.appendable {
	padding: 0.3rem 0;

	.min {
		font-size: 0.5rem;
		height: 100%;
		padding: 0 0.5rem;
	}

	.append-control {
		display: flex;
		align-items: center;

		span {
			font-size: 0.7rem;
		}
	}

	.appendable-items {

		.appendable-items-header {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			label {
				font-size: 0.8rem;
			}

			.icon-button {
				margin-left: auto;
			}
		}
	}

}
