/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

.js-loading *,
.js-loading *:before,
.js-loading *:after {
	animation-play-state: paused !important;
}

body,
html {
	padding: 0;
	margin: 0;
}

.noselect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.no-focus-outline a:focus,
.no-focus-outline button:focus {
	outline: none;
}

.App {
	font-family: "Lato";
	overflow: hidden;
	// border-radius: 1.2rem;
	background-color: #f2f5fa;
	box-shadow: 0 1px 10px 1px #a5a5a5;
	color: rgb(94, 94, 94);
	min-height: 100vh;

	header {
		height: auto;

		img {
			width: 50px;
		}
	}
	.main-sections {
		display: block;
		padding: 1rem;
	}
}
