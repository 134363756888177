.icon-text-button {
	padding: 0.5rem;
	border: 1px solid #aaa;
	background: none;
	cursor: pointer;
	border-radius: 0.5rem;

	.icon {
		margin-left: 0.3rem;
		margin-right: 0.3rem;
	}
}
