.modal {
	position: relative;

	.closebtn {
		border: none;
		background-color: transparent;
		color: grey;
		float: right;
		cursor: pointer;
		position: absolute;
		top: 0.75rem;
		right: 0.75rem;
		z-index: 1;
	}

}
