.page__customers {

	.search {
		width: 100%;
	}

	.control-header {
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;

	}

	.customers-table {
		width: 100%;
		border-collapse: collapse;

		th {
			padding: 0.75rem;
			border: 1px solid #ccc;
		}

		thead {
			background-color: rgb(196, 87, 87);
			color: white;
		}

		tbody {
			background-color: white;
			font-size: 0.9rem;
		}

		td {
			text-align: center;
			border: 1px solid #ccc;
			border-spacing: 0;
			padding: 0.5rem;
		}
	}
}
