.category {
	padding: 0.4rem;
	padding-top: 0.5rem;
	border: 2px solid rgb(121, 121, 121);
	margin-bottom: 0.6rem;

	&:first-child {
		border-top: none;
	}

	.category-header {
		display: flex;

		.category-name {
			width: 90%;
			padding: 0.5rem;
			text-align: left;
			font-weight: 600;

			.form-text {
				font-size: 1rem;
				text-align: left;

				&.desc {
					font-size: 0.9rem;
				}
			}

			label {
				text-align: center;
				font-size: 0.9rem;
			}

			.category-label {
				font-weight: 400;
				font-size: 0.85rem;
				margin-bottom: 0.2rem;
			}
		}

		.category-controls {
			padding-top: 0.6rem;
			padding-right: 0.3rem;
			width: 130px;

			.icon-text-button {
				font-size: 0.6rem;
			}

		}
	}
}
