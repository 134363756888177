.page__tables {

	.show-past {
		margin-top: 1rem;
		margin-bottom: 2rem;
	}

	.control-header {
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;

		.react-date-picker__wrapper {
			border-radius: 0.5rem;
			padding: 0.5rem;
		}
	}

	.tables-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media only screen and (max-width: 550px) {
			display: block;
		}

		.table {
			border: none;
			box-shadow: 0 1px 3px 0 rgb(88, 88, 88);
			width: 200px;
			border-radius: 0.3rem;
			display: flex;
			flex-direction: column;
			margin: 0.3rem 0.3rem;
			padding: 0;

			@media only screen and (max-width: 550px) {
				width: 100%;
				margin: 1rem 0.3rem;
			}

			.table-number {
				border-top-left-radius: 0.3rem;
				border-top-right-radius: 0.3rem;
				background-color: rgb(189, 92, 92);
				color:white;
				width: 100%;
				padding: 0.5rem;
				font-size: 1rem;
				font-weight: 600;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.add-assignment {
				color: white;
				width: 70px;
				background-color: rgb(173, 52, 52);
				padding: 0.4rem;
				font-size: 0.7rem;
			}

			.assigned-staff {
				padding: 0.5rem;
				height: 130px;
				overflow-y: auto;

				.s-ass {
					margin-bottom: 0.2rem;
					width: 100%;
					padding: 0.4rem;
					text-align: left;
					border: 1px solid rgb(189, 92, 92);
					border-radius: 0.3rem;

					.s-ass-name {
						font-weight: 600;
					}
				}
			}
		}

	}
}
