@import '../../../static/scss/variables';
@import '../../../static/scss/animations';

#modal-container {
	position: fixed;
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	transform: scale(0);
	z-index: 105;

	&.six {
		transform: scale(1);

		.modal-background {
			background: rgba(0, 0, 0, .0);
			animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

			.modal {
				&.show {
					display: flex;
					background-color: transparent;
					animation: modalFadeIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

					.closebtn {
						animation: opaque 0.5s 1s linear forwards;
					}

					.modal-content {
						width: 100%;
						z-index: 9;
						opacity: 0;
						position: relative;
						animation: modalContentFadeIn .3s 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
					}

					.modal-svg {
						rect {
							animation: sketchIn .5s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
						}
					}
				}
			}
		}

		&.out {
			animation: quickScaleDown 0s .5s linear forwards;

			.modal-background {
				animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

				.modal {
					animation: modalFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

					h2,
					p {
						animation: modalContentFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
					}

					.modal-svg {
						rect {
							animation: sketchOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
						}
					}
				}
			}
		}
	}

	.modal-background {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background: rgba(0, 0, 0, .6);
		text-align: center;
		vertical-align: middle;
		width: 100%;
		height: 100%;

		.modal {
			display: none;
			background: white;
			padding: 1.5rem;
			justify-content: center;
			align-items: center;
			border-radius: 3px;
			font-weight: 300;
			position: relative;
			margin-bottom: 1rem;
			max-width: 400px;
			min-height: 50px;

			@media only screen and (max-width: 550px) {
				max-width: 370px;
				width: 360px;
			}

			.closebtn {
				opacity: 0;
			}

			h2 {
				font-size: 25px;
				line-height: 25px;
				margin-bottom: 15px;
			}

			p {
				font-size: 18px;
				line-height: 22px;
			}

			.modal-svg {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-radius: 3px;

				rect {
					stroke: #fff;
					stroke-width: 2px;
					stroke-dasharray: 1500;
					stroke-dashoffset: 1500;
				}
			}
		}
	}
}

@keyframes opaque {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		background: rgba(0, 0, 0, .0);
	}

	100% {
		background: rgba(0, 0, 0, .5);
	}
}

@keyframes fadeOut {
	0% {
		background: rgba(0, 0, 0, .5);
	}

	100% {
		background: rgba(0, 0, 0, .0);
	}
}

@keyframes sketchIn {
	0% {
		stroke-dashoffset: 1500;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes sketchOut {
	0% {
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 1500;
	}
}

@keyframes modalFadeIn {
	0% {
		background-color: transparent;
	}

	100% {
		background-color: white;
	}
}

@keyframes modalFadeOut {
	0% {
		background-color: white;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes modalContentFadeIn {
	0% {
		opacity: 0;
		top: -20px;
	}

	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes modalContentFadeOut {
	0% {
		opacity: 1;
		top: 0px;
	}

	100% {
		opacity: 0;
		top: -20px;
	}
}
