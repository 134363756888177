.item.generic {
	padding: 0.5rem;
	border-radius: 0.2rem;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;

	.form-title {
		margin-bottom: 0.67rem;
		padding-bottom: 0.3rem;
		border-bottom: 1px solid #aaa;
	}

	.red {
		color: white;
		background-color: #990000;
		border: none;
	}

	label {
		text-align: left;
	}

	::placeholder {
		color: rgb(192, 192, 192);
		opacity: 0.8;
	}

	.item-number-group {
		max-width: 15%;
	}

	.item-price-group {
		max-width: 80px;
	}

	.item-name-group {
		width: 85%;
	}

	.item-generic-sub {
		margin: 0.3rem 0;
		padding: 0.4rem;
		border: 1px solid #ccc;
		border-radius: 0.3rem;
		max-height: 200px;
		overflow-y: auto;
		border-bottom: 2px solid #ccc;

		.item-generic-sub-title {
			margin-bottom: 0.3rem;
			text-align: center;
		}

		.item-line-container {
			display: flex;
			justify-content: center;

			.item-line-letter,
			.item-line-price {
				min-width: 85px;
				max-width: 85px;
			}

			.item-line-letter,
			.item-line-name {
				margin-right: 0.1rem;
			}

			.item-line-name {
				max-width: 80%;
			}
		}
	}
}
