.notification-container {
	padding: 0 0.3rem;
	border-radius: 0.4rem;
	color: white;
	display: inline-block;
	margin: 0.3rem;
	font-size: 0.7rem;

	.notification-content {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.close-noti {
		margin: 0 0.3rem;
	}

	&.error {
		background-color: rgb(211, 31, 31);
	}

	&.success {
		background-color: rgb(37, 148, 37);

	}
}
