@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-from-top {
  0% {
    opacity: 0;
    transform: translateY(-0.7rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(0.7rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-scale {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes floatingright {
	0% {
		transform: translateX(1px);
	}
	50% {
		transform: translateX(-2px);
	}
	100% {
		transform: translateX(1px);
	}
}
@keyframes floatingleft {
	0% {
		transform: translateX(-2px);
	}

	50% {
		transform: translateX(1px);
	}

	100% {
		transform: translateX(-2px);
	}
}

@keyframes circle-creation {
   0%{border-color: transparent; transform: rotate(0deg);}
   25%{border-color: transparent #fff transparent transparent; transform: rotate(-35deg);}
   50%{border-color: transparent #fff  #fff transparent; }
   75%{border-color: transparent #fff #fff #fff;  }
   100%{border-color: #fff; transform: rotate(-300deg)}

}
