header {
	background-color: rgb(144, 34, 34);
	position: relative;

	nav {
		display: flex;

		.logo-container {
			padding: 1rem;
			text-align: center;

			img {}
		}

		ul {
			list-style: none;
			padding-left: 0;
			display: flex;

			li {
				position: relative;
				background-color: rgb(144, 34, 34);
				padding-left: 0.5rem;

				&.active {

					// &::before,
					// &::after {
					// 	position: absolute;
					// 	right: 0;
					// 	bottom: 100%;
					// 	background-color: rgb(144, 34, 34);
					// 	content: " ";
					// 	height: 2rem;
					// 	width: 1rem;
					// }

					// &::before {
					// 	background-color: rgb(144, 34, 34);
					// 	border-bottom-right-radius: 50%;
					// }
					// &::after {
					// 	background-color: rgb(144, 34, 34);
					// 	top: 100%;
					// }

					a {
						// background-color: #f2f5fa;
						// color: rgb(94, 94, 94);
						border-bottom: 2px solid white;
					}

					// & + li {
					// 	background-color: #f2f5fa;

					// 	a {
					// 		border-top-right-radius: 1.5rem;
					// 		background-color: rgb(144, 34, 34);
					// 	}
					// }
				}

				&:hover {
					a {
						// padding-left: 1rem;
						// transition: padding-left 0.3s ease-in-out;
					}
				}

				a {
					display: block;
					padding: 1rem 0.8rem;
					text-decoration: none;
					color: rgb(228, 228, 228);
				}
			}


		}
	}



	.ctrl {
		padding: 1rem;
		position: absolute;
		width: 100%;
		color: rgb(228, 228, 228);
		bottom: 0%;
		display: flex;
		justify-content: center;
		align-items: center;

		.signout {
			color: rgb(228, 228, 228);
			background-color: transparent;
			border: none;
			cursor: pointer;
		}
	}
}
