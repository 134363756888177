.icon-button {
	padding: 0.5rem;
	border: 1px solid #aaa;
	background: none;
	cursor: pointer;
	border-radius: 50%;
	height: 30px;

	.icon {
	}
}
