.item.generic.display,
.item.sub.display {
	padding: 0.5rem;
	border: 1px solid #aaa;
	border-radius: 0.2rem;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	border-bottom: 2px solid #aaa;

	.item-header {
		display: flex;
		border-bottom: 1px solid #ccc;
		padding: 0.2rem;

		.left {
			width: 85%;
		}
		.right {
			min-width: 50px;
			display: flex;
			justify-content: center;
			align-items: center;

			.icon-text-button {
				font-size: 0.6rem;
			}

			.icon-button {
				margin-right: 0.4rem;

				svg {
					transform: translateY(-1px);
				}
			}

		}

	}

	.val {
		font-weight: 600;
	}

	.row {
		padding: 0.3rem;

	}

	.flex-group {
		display: flex;

		.row {
			width: 50%;
		}
	}

	.item-body {
		padding: 0.3rem;
		font-size: 0.85rem;

		label {
			font-size: 0.75rem;
		}
	}
}

.item.sub.display {
	.item-sub-display-title {
		text-align: center;
	}

	.item-body {
		border-bottom: 2px solid #ccc;
		margin-bottom: 0.5rem;

		&:last-child {

		}
	}
}
