.table-modal {
	width: 300px;

	.table-number {
		padding: 1rem;
		font-size: 1.2rem;
		font-weight: 600;
		border-bottom: 1px solid #ccc;
		margin-bottom: 1rem;
	}

	.date {
		font-size: 1rem;
	}

	.input-group {
		margin: 0.3rem 0;
		display: flex;

		.label {
			min-width: 50px;
			text-align: left;
			display: flex;
			align-items: center;
		}
		.input-container {
			width: 90%;

			select, input {
				width: 100%;
				padding: 0.3rem;
				font-size: 1rem;
			}

		}
	}

	.submit-control {
		border-top: 1px solid #ccc;
		margin-top: 1rem;
		padding: 1rem;
		display: flex;
		justify-content: center;

		.submit {
			background-color: rgb(189, 92, 92);
			color: white;
			border: none;
			padding: 1rem;
			margin-right: 0.5rem;
		}
	}
}
