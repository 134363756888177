.form-group {
	display: block;
	margin-bottom: 0.3rem;

	label {
		font-size: 0.8rem;
		display: block;
	}

	.form-text,
	.form-textarea {
		padding: 0.5rem;
		border-radius: 0.3rem;
		border: 1px solid #ccc;
		color: #333333;
		width: 100%;
	}

	.form-textarea {
		resize: vertical;
		padding: 0.3rem;
		width: 100%;
	}

}

.flex-form-group {
	display: flex;

	.form-group {
		margin-right: 0.4rem;

		&:last-child {
			margin-right: 0;
		}
	}
}

.no-padding {
	padding: 0 !important;
}

.no-border {
	border: none !important;
}

::placeholder {
	color: rgb(192, 192, 192);
	opacity: 0.9;
}

.disabled {
	position: relative;
	background-color: rgba(100, 100, 100, 0.3);

	// &:after {
	// 	display: block;
	// 	position: absolute;
	// 	height: 100%;
	// 	width: 100%;
	// 	content: ' ';
	// 	background-color: rgba(100, 100, 100,0.3);
	// }
}
