.menu-admin-container {
	width: 100%;

	.menu-admin-items {
		border: 2px solid rgb(117, 117, 117);
		border-top: none;
		padding: 0.3rem;
	}

	.menu-admin-header {
		border-bottom: 2px solid rgb(121, 121, 121);
		padding: 0.5rem 0.3rem;
		display: flex;

		&.expanded {
			background-color: #902222;
			color: white;

			button {
				color: white;
				border-color: white;
			}
		}

		.menu-admin-header-expand {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 0.5rem;
		}

		.menu-admin-header-content {
			width: 100%;

			.menu-admin-header-title {
				padding: 0.3rem;
				display: flex;
				justify-content: flex-start;

				.small {
					margin-left: auto;
				}

			}

			.menu-admin-columns {
				padding: 0.4rem;
				padding-top: 0;

				label {
					font-size: 0.9rem;
					font-weight: 600;
				}

				.columns {
					padding-left: 0.3rem;

					.column {
						font-size: 0.8rem;
					}
				}

			}
		}

		.menu-admin-header-controls {
			border-top: 1px solid #ccc;
			margin-left: auto;
			padding: 0.4rem;

			.icon-text-button {
				margin: 0 0.1rem;
				font-size: 0.55rem;
			}
		}

	}

	.menu-admin-extra-charges {
		padding: 0.3rem 0;
	}

	.small {
		font-size: 0.6rem;
	}
}
