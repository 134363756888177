.add-menu-page {
	padding: 0.3rem;
	z-index: 999;

	label {
		margin: 0.5rem 0;
	}

	.red {
		color: white;
		background-color: #990000;
		border: none;
	}

	.columns-control {
		margin: 0.3rem 0;
		margin-bottom: 0.4rem;
	}

	.submit-section {
		border-top: 1px solid #ccc;
		padding-top: 1rem;
	}
}
